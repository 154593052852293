export default {
  name: "foot",
  data() {
    return {
      list: [{
        title: '中国法学会',
        url: 'https://www.chinalaw.org.cn/'
      }, {
        title: '贵州省法学会',
        url: 'http://www.gzsfxh.org.cn/'
      }, {
        title: '贵阳市人民政府网',
        url: 'http://www.guiyang.gov.cn/'
      }, {
        title: '贵州省人民政府网',
        url: 'http://www.guizhou.gov.cn/'
      }, {
        title: '贵州省高级人民法院',
        url: 'http://www.guizhoucourt.gov.cn/'
      }],
      htmlStr: ''
    };
  },
  mounted() {
    // this.htmlStr = '<script type="text/javascript">document.write(unescape("' + "%3Cspan id='_ideConac' %3E%3C/span%3E%3Cscript src='https://dcs.conac.cn/js/24/354/0000/60960597/CA243540000609605970001.js' type='text/javascript'" + ' %3E%3C/script%3E"));<' + '/script>'
    // this.htmlStr = '<script type="text/javascript">document.write("' + "<span id='_ideConac'></span><script src='https://dcs.conac.cn/js/24/354/0000/60960597/CA243540000609605970001.js' type='text/javascript'>" + '<' + '/' + 'script>' + '");' + '<' + '/' + 'script>'
    // console.log(this.htmlStr);
  },
  methods: {}
};